const allName = [
  "妙蛙種子",
  "妙蛙草",
  "妙蛙花",
  "小火龍",
  "火恐龍",
  "噴火龍",
  "傑尼龜",
  "卡咪龜",
  "水箭龜",
  "綠毛蟲",
  "鐵甲蛹",
  "巴大蝶",
  "獨角蟲",
  "鐵殼蛹",
  "大針蜂",
  "波波",
  "比比鳥",
  "大比鳥",
  "小拉達",
  "拉達",
  "烈雀",
  "大嘴雀",
  "阿柏蛇",
  "阿柏怪",
  "皮卡丘",
  "雷丘",
  "穿山鼠",
  "穿山王",
  "尼多蘭",
  "尼多娜",
  "尼多后",
  "尼多朗",
  "尼多力諾",
  "尼多王",
  "皮皮",
  "皮可西",
  "六尾",
  "九尾",
  "胖丁",
  "胖可丁",
  "超音蝠",
  "大嘴蝠",
  "走路草",
  "臭臭花",
  "霸王花",
  "派拉斯",
  "派拉斯特",
  "毛球",
  "摩魯蛾",
  "地鼠",
  "三地鼠",
  "喵喵",
  "貓老大",
  "可達鴨",
  "哥達鴨",
  "猴怪",
  "火暴猴",
  "卡蒂狗",
  "風速狗",
  "蚊香蝌蚪",
  "蚊香君",
  "蚊香泳士",
  "凱西",
  "勇基拉",
  "胡地",
  "超級胡地",
  "腕力",
  "豪力",
  "怪力",
  "喇叭芽",
  "口呆花",
  "大食花",
  "瑪瑙水母",
  "毒刺水母",
  "小拳石",
  "隆隆石",
  "隆隆岩",
  "小火馬",
  "烈焰馬",
  "呆呆獸",
  "呆殼獸",
  "小磁怪",
  "三合一磁怪",
  "大蔥鴨",
  "嘟嘟",
  "嘟嘟利",
  "小海獅",
  "白海獅",
  "臭泥",
  "臭臭泥",
  "大舌貝",
  "刺甲貝",
  "鬼斯",
  "鬼斯通",
  "耿鬼",
  "大岩蛇",
  "催眠貘",
  "引夢貘人",
  "大鉗蟹",
  "巨鉗蟹",
  "霹靂電球",
  "頑皮雷彈",
  "蛋蛋",
  "椰蛋樹",
  "卡拉卡拉",
  "嘎啦嘎啦",
  "飛腿郎",
  "快拳郎",
  "大舌頭",
  "瓦斯彈",
  "雙彈瓦斯",
  "獨角犀牛",
  "鑽角犀獸",
  "吉利蛋",
  "蔓藤怪",
  "袋獸",
  "墨海馬",
  "海刺龍",
  "角金魚",
  "金魚王",
  "海星星",
  "寶石海星",
  "魔牆人偶",
  "飛天螳螂",
  "迷唇姐",
  "電擊獸",
  "鴨嘴火獸",
  "凱羅斯",
  "肯泰羅",
  "鯉魚王",
  "暴鯉龍",
  "拉普拉斯",
  "百變怪",
  "伊布",
  "水伊布",
  "雷伊布",
  "火伊布",
  "多邊獸",
  "菊石獸",
  "多刺菊石獸",
  "化石盔",
  "鐮刀盔",
  "化石翼龍",
  "卡比獸",
  "急凍鳥",
  "閃電鳥",
  "火焰鳥",
  "迷你龍",
  "哈克龍",
  "快龍",
  "超夢",
  "夢幻",
  "菊草葉",
  "月桂葉",
  "大竺葵",
  "火球鼠",
  "火暴獸",
  "小鋸鱷",
  "藍鱷",
  "大力鱷",
  "尾立",
  "大尾立",
  "咕咕",
  "貓頭夜鷹",
  "芭瓢蟲",
  "安瓢蟲",
  "圓絲蛛",
  "阿利多斯",
  "叉字蝠",
  "燈籠魚",
  "電燈怪",
  "皮丘",
  "皮寶寶",
  "寶寶丁",
  "波克比",
  "波克基古",
  "天然雀",
  "天然鳥",
  "咩利羊",
  "茸茸羊",
  "電龍",
  "美麗花",
  "瑪力露",
  "瑪力露麗",
  "樹才怪",
  "蚊香蛙皇",
  "毽子草",
  "毽子花",
  "毽子棉",
  "長尾怪手",
  "向日種子",
  "向日花怪",
  "蜻蜻蜓",
  "烏波",
  "沼王",
  "太陽伊布",
  "月亮伊布",
  "黑暗鴉",
  "呆呆王",
  "夢妖",
  "未知圖騰",
  "果然翁",
  "麒麟奇",
  "榛果球",
  "佛烈托斯",
  "土龍弟弟",
  "天蠍",
  "大鋼蛇",
  "布魯",
  "布魯皇",
  "千針魚",
  "巨鉗螳螂",
  "壺壺",
  "赫拉克羅斯",
  "狃拉",
  "熊寶寶",
  "圈圈熊",
  "熔岩蟲",
  "熔岩蝸牛",
  "小山豬",
  "長毛豬",
  "太陽珊瑚",
  "鐵炮魚",
  "章魚桶",
  "信使鳥",
  "巨翅飛魚",
  "盔甲鳥",
  "戴魯比",
  "黑魯加",
  "刺龍王",
  "小小象",
  "頓甲",
  "多邊獸Ⅱ",
  "驚角鹿",
  "圖圖犬",
  "無畏小子",
  "戰舞郎",
  "迷唇娃",
  "電擊怪",
  "鴨嘴寶寶",
  "大奶罐",
  "幸福蛋",
  "雷公",
  "炎帝",
  "水君",
  "幼基拉斯",
  "沙基拉斯",
  "班基拉斯",
  "洛奇亞",
  "鳳王",
  "時拉比",
  "木守宮",
  "森林蜥蜴",
  "蜥蜴王",
  "火稚雞",
  "力壯雞",
  "火焰雞",
  "水躍魚",
  "沼躍魚",
  "巨沼怪",
  "土狼犬",
  "大狼犬",
  "蛇紋熊",
  "直衝熊",
  "刺尾蟲",
  "甲殼繭",
  "狩獵鳳蝶",
  "盾甲繭",
  "毒粉蛾",
  "蓮葉童子",
  "蓮帽小童",
  "樂天河童",
  "橡實果",
  "長鼻葉",
  "狡猾天狗",
  "傲骨燕",
  "大王燕",
  "長翅鷗",
  "大嘴鷗",
  "拉魯拉絲",
  "奇魯莉安",
  "沙奈朵",
  "溜溜糖球",
  "雨翅蛾",
  "蘑蘑菇",
  "斗笠菇",
  "懶人獺",
  "過動猿",
  "請假王",
  "土居忍士",
  "鐵面忍者",
  "脫殼忍者",
  "咕妞妞",
  "吼爆彈",
  "爆音怪",
  "幕下力士",
  "鐵掌力士",
  "露力麗",
  "朝北鼻",
  "向尾喵",
  "優雅貓",
  "勾魂眼",
  "大嘴娃",
  "可可多拉",
  "可多拉",
  "波士可多拉",
  "瑪沙那",
  "恰雷姆",
  "落雷獸",
  "雷電獸",
  "正電拍拍",
  "負電拍拍",
  "電螢蟲",
  "甜甜螢",
  "毒薔薇",
  "溶食獸",
  "吞食獸",
  "利牙魚",
  "巨牙鯊",
  "吼吼鯨",
  "吼鯨王",
  "呆火駝",
  "噴火駝",
  "煤炭龜",
  "跳跳豬",
  "噗噗豬",
  "晃晃斑",
  "大顎蟻",
  "超音波幼蟲",
  "沙漠蜻蜓",
  "刺球仙人掌",
  "夢歌仙人掌",
  "青綿鳥",
  "七夕青鳥",
  "貓鼬斬",
  "飯匙蛇",
  "月石",
  "太陽岩",
  "泥泥鰍",
  "鯰魚王",
  "龍蝦小兵",
  "鐵螯龍蝦",
  "天秤偶",
  "念力土偶",
  "觸手百合",
  "搖籃百合",
  "太古羽蟲",
  "太古盔甲",
  "醜醜魚",
  "美納斯",
  "飄浮泡泡",
  "變隱龍",
  "怨影娃娃",
  "詛咒娃娃",
  "夜巡靈",
  "彷徨夜靈",
  "熱帶龍",
  "風鈴鈴",
  "阿勃梭魯",
  "小果然",
  "雪童子",
  "冰鬼護",
  "海豹球",
  "海魔獅",
  "帝牙海獅",
  "珍珠貝",
  "獵斑魚",
  "櫻花魚",
  "古空棘魚",
  "愛心魚",
  "寶貝龍",
  "甲殼龍",
  "暴飛龍",
  "鐵啞鈴",
  "金屬怪",
  "巨金怪",
  "雷吉洛克",
  "雷吉艾斯",
  "雷吉斯奇魯",
  "拉帝亞斯",
  "拉帝歐斯",
  "蓋歐卡",
  "固拉多",
  "烈空坐",
  "基拉祈",
  "代歐奇希斯",
  "草苗龜",
  "樹林龜",
  "土台龜",
  "小火焰猴",
  "猛火猴",
  "烈焰猴",
  "波加曼",
  "波皇子",
  "帝王拿波",
  "姆克兒",
  "姆克鳥",
  "姆克鷹",
  "大牙狸",
  "大尾狸",
  "圓法師",
  "音箱蟀",
  "小貓怪",
  "勒克貓",
  "倫琴貓",
  "含羞苞",
  "羅絲雷朵",
  "頭蓋龍",
  "戰槌龍",
  "盾甲龍",
  "護城龍",
  "結草兒",
  "結草貴婦",
  "紳士蛾",
  "三蜜蜂",
  "蜂女王",
  "帕奇利茲",
  "泳圈鼬",
  "浮潛鼬",
  "櫻花寶",
  "櫻花兒",
  "無殼海兔",
  "海兔獸",
  "雙尾怪手",
  "飄飄球",
  "隨風球",
  "卷卷耳",
  "長耳兔",
  "夢妖魔",
  "烏鴉頭頭",
  "魅力喵",
  "東施喵",
  "鈴鐺響",
  "臭鼬噗",
  "坦克臭鼬",
  "銅鏡怪",
  "青銅鐘",
  "盆才怪",
  "魔尼尼",
  "小福蛋",
  "聒噪鳥",
  "花岩怪",
  "圓陸鯊",
  "尖牙陸鯊",
  "烈咬陸鯊",
  "小卡比獸",
  "利歐路",
  "路卡利歐",
  "沙河馬",
  "河馬獸",
  "鉗尾蠍",
  "龍王蠍",
  "不良蛙",
  "毒骷蛙",
  "尖牙籠",
  "螢光魚",
  "霓虹魚",
  "小球飛魚",
  "雪笠怪",
  "暴雪王",
  "瑪狃拉",
  "自爆磁怪",
  "大舌舔",
  "超甲狂犀",
  "巨蔓藤",
  "電擊魔獸",
  "鴨嘴炎獸",
  "波克基斯",
  "遠古巨蜓",
  "葉伊布",
  "冰伊布",
  "天蠍王",
  "象牙豬",
  "多邊獸Z",
  "艾路雷朵",
  "大朝北鼻",
  "黑夜魔靈",
  "雪妖女",
  "洛托姆",
  "由克希",
  "艾姆利多",
  "亞克諾姆",
  "帝牙盧卡",
  "帕路奇亞",
  "席多藍恩",
  "雷吉奇卡斯",
  "騎拉帝納",
  "克雷色利亞",
  "霏歐納",
  "瑪納霏",
  "達克萊伊",
  "謝米",
  "阿爾宙斯",
  "比克提尼",
  "藤藤蛇",
  "青藤蛇",
  "君主蛇",
  "暖暖豬",
  "炒炒豬",
  "炎武王",
  "水水獺",
  "雙刃丸",
  "大劍鬼",
  "探探鼠",
  "步哨鼠",
  "小約克",
  "哈約克",
  "長毛狗",
  "扒手貓",
  "酷豹",
  "花椰猴",
  "花椰猿",
  "爆香猴",
  "爆香猿",
  "冷水猴",
  "冷水猿",
  "食夢夢",
  "夢夢蝕",
  "豆豆鴿",
  "咕咕鴿",
];
export default allName;
